import React from "react"

import { LocaleProvider } from "../../context/Locale"

function Layout({ children, location, pageContext: { locale } }) {
  return (
    <React.Fragment>
      <LocaleProvider locale={locale} location={location}>
        {children}
      </LocaleProvider>
    </React.Fragment>
  )
}

export default Layout
