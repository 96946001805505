exports.components = {
  "component---src-components-pages-contact-contact-page-jsx": () => import("./../../../src/components/pages/contact/contactPage.jsx" /* webpackChunkName: "component---src-components-pages-contact-contact-page-jsx" */),
  "component---src-components-pages-direct-index-jsx": () => import("./../../../src/components/pages/direct/index.jsx" /* webpackChunkName: "component---src-components-pages-direct-index-jsx" */),
  "component---src-components-pages-faq-faq-page-jsx": () => import("./../../../src/components/pages/faq/faqPage.jsx" /* webpackChunkName: "component---src-components-pages-faq-faq-page-jsx" */),
  "component---src-components-pages-gdpr-gdpr-page-jsx": () => import("./../../../src/components/pages/gdpr/gdprPage.jsx" /* webpackChunkName: "component---src-components-pages-gdpr-gdpr-page-jsx" */),
  "component---src-components-pages-home-home-page-jsx": () => import("./../../../src/components/pages/home/homePage.jsx" /* webpackChunkName: "component---src-components-pages-home-home-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

